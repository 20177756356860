import { useABTestIsOn } from '@modules/root/hook/use-growthbook';
import { useLocation } from '@remix-run/react';
import { potmHeroWhiteListExactMatch } from '@modules/navigation/config/secondary-nav-block-list';

export function usePotmHeroEnabled() {
    const potmFeatureOn = useABTestIsOn('directory-potms');
    const location = useLocation();
    const onDirectoryRoot = potmHeroWhiteListExactMatch.some(
        (url) => location.pathname === url
    );
    const params = new URLSearchParams(location.search);
    const noFilters =
        Array.from(params.keys()).length === 0 ||
        (params.has('page') && Array.from(params.keys()).length === 1);

    return onDirectoryRoot && noFilters && potmFeatureOn;
}
