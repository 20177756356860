/** hide second nav on these routes */
export const secondaryNavBlockList = [
    '/home-design-event',
    '/ace-2023',
    '/about-us',
    '/contact-us',
    '/advertising',
    '/digital-magazines',
    '/digital-magazine',
    '/ecommerce-launch',
    '/member',
];

export const potmHeroWhiteListExactMatch = ['/projects', '/projects/'];
